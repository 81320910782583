import React, { useCallback, useMemo } from 'react';

import dayjs from 'dayjs';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { tournamentsClient } from 'src/apollo/client';
import { useOrgId } from 'src/apollo/local-state';
import AdminTable, { DataCols } from 'src/components/admin-table/admin-table';
import Button from 'src/components/button/button';
import PageHeader from 'src/components/page-header/page-header';
import Panel from 'src/components/panel/panel';
import { useControlledQuery } from 'src/components/table-controls/table-controls';
import { Category } from 'src/components/tournament-attributes/tournament-attributes';
import { PageMaxWidth } from 'src/components/util-components/util-components';
import {
  GetTournamentPools,
  GetTournamentPoolsVariables,
  GetTournamentPools_paginatedTournamentPools_items as TournamentPool,
} from 'src/graphql-types/GetTournamentPools';

import { GET_TOURNAMENT_GROUPS } from './tournament-groups-overview-queries';
import * as styles from './tournament-groups-overview.module.less';

const TournamentGroups: React.FC = () => {
  const { t } = useTranslation();

  const orgId = useOrgId();
  const getTotalItems = useCallback((d: GetTournamentPools) => d.paginatedTournamentPools.totalItems, []);

  const { data, error, loading, controlProps, refetch } = useControlledQuery<
    GetTournamentPools,
    GetTournamentPoolsVariables,
    TournamentPool
  >(GET_TOURNAMENT_GROUPS, {
    client: tournamentsClient,
    variables: { orgId },
    getTotalItems,
    notifyOnNetworkStatusChange: true,
  });

  const handleRowClick = (tg) => {
    navigate(`/tournaments/groups/${tg.id}`);
  };

  const cols: DataCols<TournamentPool> = useMemo(() => {
    return [
      { key: 'name', title: t('group name'), getValue: (g) => g.name },
      {
        key: 'category',
        title: t('circuit'),
        getValue: (g) => <Category category={g.category} />,
      },
      {
        key: 'level',
        title: t('level'),
        getValue: (g) => g.level.name,
      },
      {
        key: 'tournamentCount',
        title: t('num tournaments'),
        getValue: (g) => g.tournamentCount,
      },
      {
        key: 'fromDate',
        title: t('date range'),
        getValue: (g) =>
          t('tournament group dates', {
            dates: { start: dayjs(g.fromDate), end: dayjs(g.toDate) },
          }),
      },
      {
        key: 'createdByFirstName',
        title: t('created by'),
        getValue: (g) => `${g.createdByFirstName ?? ''} ${g.createdByLastName ?? ''}`,
      },
    ];
  }, [t, data]);

  return (
    <PageMaxWidth>
      <PageHeader title={t('tournament groups')} />
      <Panel
        headerEndContent={
          <Button onClick={() => navigate('/tournaments/groups/create-tournament-group')}>{t('add group')}</Button>
        }
      >
        <AdminTable
          error={error?.message}
          controls={controlProps}
          data={data?.paginatedTournamentPools.items}
          columns={cols}
          loading={loading}
          onRowClick={(tg) => handleRowClick(tg)}
        />
      </Panel>
    </PageMaxWidth>
  );
};

export default TournamentGroups;

import React from 'react';

import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Caps } from 'src/components/util-components/util-components';
import {
  GetEvents_tournament_events_division_ageCategory as AgeCategoryBaseType,
  GetEvents_tournament_events as Event,
  GetEvents_tournament_events_division_ratingCategory as RatingCategory,
} from 'src/graphql-types/GetEvents';
import {
  AgeCategoryType as AgeCategoryTypeType,
  BallColour,
  Category as CategoryType,
  EventFormat,
  EventTypeEnum,
  FamilyType,
  Gender as GenderType,
  RatingCategoryType,
  RatingType,
  Surface as SurfaceType,
  USTAWheelchairRating,
} from 'src/graphql-types/globalTournamentTypes';

const { BOYS, GIRLS, MIXED, COED } = GenderType;
const { ARTIFICIAL, CLAY, GREEN_CLAY, RED_CLAY, GRASS, HARD } = SurfaceType;
const { NTRP, WTN } = RatingType;
const { SINGLES, DOUBLES, TEAM } = EventTypeEnum;
const { COMBINED, INDIVIDUAL } = AgeCategoryTypeType;
const {
  GRANDFATHER_GRANDSON,
  HUSBAND_WIFE,
  SAME_GENDER_FEMALE,
  MOTHER_DAUGHTER,
  SAME_GENDER_MALE,
  FATHER_DAUGHTER,
  FATHER_SON,
  MOTHER_SON,
} = FamilyType;

interface Props {
  event: Event;
}

const ratingTypeString = (rc: RatingCategory | null, t: TFunction) => {
  if (rc) {
    let rt = '';
    if (rc.ratingType === NTRP) rt = t('ntrp');
    if (rc.ratingType === WTN) rt = t('wtn');
    if (rc.ratingCategoryType === RatingCategoryType.COMBINED) rt += ` ${t('combo')}`;

    rt += ` ${rc.value !== null ? rc.value.toFixed(1) : t('open')}`;
    return rt;
  }
};

const familyEventName = (family: FamilyType, age: AgeCategoryBaseType, ballColour: BallColour | null, t: TFunction) => {
  let str = '';

  // individual age types with minimum ages are parent/child combos
  if (age.type === INDIVIDUAL && age.minimumAge) {
    let players: { parent?: 'mother' | 'father'; child?: 'daughter' | 'son' } = {};
    if (family === FATHER_SON) {
      players = { parent: 'father', child: 'son' };
    } else if (family === FATHER_DAUGHTER) {
      players = { parent: 'father', child: 'daughter' };
    } else if (family === MOTHER_SON) {
      players = { parent: 'mother', child: 'son' };
    } else if (family === MOTHER_DAUGHTER) {
      players = { parent: 'mother', child: 'daughter' };
    }
    str += t('aged parent child division', { ...players, minAge: age.minimumAge });
  } else {
    if (family === GRANDFATHER_GRANDSON) str += t('grandfather grandson');
    if (family === HUSBAND_WIFE) str += t('husband wife');
    if (family === SAME_GENDER_FEMALE) str += t('same gender female');
    if (family === MOTHER_DAUGHTER) str += `${t('mother')} - ${t('daughter')}`;
    if (family === SAME_GENDER_MALE) str += t('same gender male');
    if (family === FATHER_DAUGHTER) str += `${t('father')} - ${t('daughter')}`;
    if (family === FATHER_SON) str += `${t('father')} - ${t('son')}`;
    if (family === MOTHER_SON) str += `${t('mother')} - ${t('son')}`;
  }
  if (age.type === COMBINED) {
    str += ` (Combined ${age.minimumAge})`;
  }

  const ballColourStr = ballColourString(ballColour, t);
  if (ballColourStr) {
    str += ` - ${ballColourStr}`;
  }

  return str;
};

const eventName = (
  {
    division: { gender, ageCategory: age, eventType: type, ratingCategory, familyType, wheelchairRating, ballColour },
    level: { category },
  }: Event,
  t: TFunction,
) => {
  if (familyType) {
    return familyEventName(familyType, age, ballColour, t);
  } else {
    const coedStr = wheelchairRatingString(wheelchairRating, t);
    const genderStr = t(genderString(gender, category));
    const ageStr = ageString(age, t, wheelchairRating);
    const eventStr = t(eventTypeString(type)).toLowerCase();
    const ratingStr = ratingTypeString(ratingCategory, t);
    const ballColourStr = ballColourString(ballColour, t);

    if (isCustomDivision(ratingCategory, age)) {
      return `${genderStr} ${eventStr} ${customDivisionRangesString(ratingCategory, age)}`;
    }

    let str = `${genderStr} ${coedStr} ${ageStr} ${eventStr}`;
    if (ratingStr) str = `${ratingStr} ${str}`;
    if (ballColourStr) str += ` - ${ballColourStr}`;
    return str;
  }
};

const ballColourString = (ballColour: BallColour | null, t: TFunction) => {
  // We don't add Yellow to since this is the default option
  if (ballColour === BallColour.ORANGE) return t('orange');
  if (ballColour === BallColour.GREEN) return t('green');
  if (ballColour === BallColour.RED) return t('red');
  return '';
};

const wheelchairRatingString = (r: USTAWheelchairRating | null, t: TFunction) => {
  if (r === USTAWheelchairRating.A) return t('wheelchair a');
  if (r === USTAWheelchairRating.B) return t('wheelchair b');
  if (r === USTAWheelchairRating.C) return t('wheelchair c');
  if (r === USTAWheelchairRating.D) return t('wheelchair d');
  if (r === USTAWheelchairRating.Q) return t('wheelchair q');
  return '';
};

const genderString = (g: GenderType, c: CategoryType): string => {
  const junior = c === CategoryType.JUNIOR;

  if ([CategoryType.WTN_PLAY, CategoryType.PICKLE_BALL].includes(c)) {
    if (g === BOYS) return 'male';
    if (g === GIRLS) return 'female';
  }
  if (g === BOYS) return junior ? 'boys' : 'mens';
  if (g === GIRLS) return junior ? 'girls' : 'womens';
  if (g === COED) return 'coed';
  if (g === MIXED) return 'mixed';
  return g;
};

const surfaceString = (s: SurfaceType): string => {
  if (s === ARTIFICIAL) return 'artificial';
  if (s === CLAY) return 'clay';
  if (s === GRASS) return 'grass';
  if (s === GREEN_CLAY) return 'green clay';
  if (s === HARD) return 'hard';
  if (s === RED_CLAY) return 'red clay';
  return s;
};

const eventTypeString = (et: EventTypeEnum): string => {
  if (et === DOUBLES) return 'doubles';
  if (et === SINGLES) return 'singles';
  if (et === TEAM) return 'team';
  return et;
};

const drawTypeString = (f: EventFormat | undefined): string => {
  return f ? `draw type ${f.toLowerCase()}` : 'n/a';
};

const ageString = (ac: AgeCategoryBaseType | null, t: TFunction, r?: USTAWheelchairRating | null): string => {
  const wheelchair =
    r === USTAWheelchairRating.A ||
    r === USTAWheelchairRating.B ||
    r === USTAWheelchairRating.C ||
    r === USTAWheelchairRating.D;
  if (!ac || (!ac.maximumAge && !ac.minimumAge)) return wheelchair ? '' : t('open');
  const { maximumAge: max, minimumAge: min } = ac;
  let age = '';
  if (max) age += t('under age', { age: max });
  if (min && max) age += ', ';
  if (min) age += t('over age', { age: min });
  return age;
};

const categoryString = (c?: CategoryType | null): string => {
  if (c === CategoryType.ADULT) return 'adult';
  if (c === CategoryType.JUNIOR) return 'junior';
  if (c === CategoryType.WHEELCHAIR) return 'wheelchair';
  if (c === CategoryType.WTN_PLAY) return 'wtn play';
  if (c === CategoryType.PICKLE_BALL) return 'pickleball';
  return 'n/a';
};

const isCustomDivision = (ratingCategory: RatingCategory | null, age: AgeCategoryBaseType | null) => {
  const { minimumValue = null, maximumValue = null } = ratingCategory ?? {};
  const { maximumAge = null, minimumAge = null } = age ?? {};
  const isCustomRating = minimumValue !== null && maximumValue !== null;
  const isCustomAge = maximumAge !== null && minimumAge !== null;
  return isCustomRating || isCustomAge;
};

const customDivisionRangesString = (ratingCategory: RatingCategory | null, age: AgeCategoryBaseType | null) => {
  const ratingRange = ratingRangeString(ratingCategory);
  const ageRange = ageRangeString(age);
  const conjunction = ratingRange && ageRange ? ', ' : '';
  return `${ratingRange}${conjunction}${ageRange}`;
};

const ratingRangeString = (ratingCategory: RatingCategory | null) => {
  if (!ratingCategory) return '';
  const rc = ratingCategory;
  const rt = ratingCategory.ratingType;
  const type = (() => {
    if (rt === RatingType.NTRP) return 'NTRP';
    if (rt === RatingType.WTN) return 'WTN';
    return '';
  })();
  return `${type} ${rc.minimumValue} - ${rc.maximumValue}`;
};

const ageRangeString = (age: AgeCategoryBaseType | null) => {
  const hasAgeRange = age && age.maximumAge !== null && age.minimumAge !== null;
  return hasAgeRange ? `age ${age.minimumAge} - ${age.maximumAge}` : '';
};

export const EventName: React.FC<Props> = ({ event }) => {
  const { t } = useTranslation();
  return <>{eventName(event, t)}</>;
};

export const AgeCategory: React.FC<Props> = ({ event }) => {
  const { t } = useTranslation();
  return <>{ageString(event.division.ageCategory, t)}</>;
};

export const Gender: React.FC<Props> = ({ event: { division, level } }) => {
  const { t } = useTranslation();
  return <>{t(genderString(division.gender, level.category))}</>;
};

export const Format: React.FC<Props> = ({ event }) => {
  const { t } = useTranslation();
  return t(eventTypeString(event.division.eventType));
};

export const Surface: React.FC<Props> = ({ event }) => {
  const { t } = useTranslation();
  return <>{event.surface ? `${t(surfaceString(event.surface))}` : t('n/a')}</>;
};

export const DrawType: React.FC<Props> = ({ event }) => {
  const { t } = useTranslation();
  return (
    <>
      {t(
        drawTypeString(
          event?.division?.eventType === EventTypeEnum.TEAM
            ? event?.teamEventConfiguration?.eventFormat
            : event?.formatConfiguration?.eventFormat,
        ),
      )}
    </>
  );
};

export interface CategoryProps {
  category?: CategoryType | null;
}

export const Category: React.FC<CategoryProps> = ({ category }) => {
  const { t } = useTranslation();
  return <Caps>{t(categoryString(category))}</Caps>;
};
